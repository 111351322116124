
.HomeWorkDetail {
    box-sizing: border-box;
    height: 100%;
    padding: 75px 181px;
    [class^='work'] {
        margin-bottom: 16px;
        &.work-name {
            margin-bottom: 50px;
        }
        &.work-material,
        &.work-task {
            margin-bottom: 40px;
        }
        &.work-material {
            display: flex;
            align-items: center;
        }
        &.work-task .upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
            height: 160px;
            margin: 10px 0;
            color: #aaa;
            background-color: #f5f7fa;
            border: 1px solid #ddd;
            border-radius: 4px;
            cursor: pointer;
            transition: border-color .3s;
            &:hover {
                border-color: #2821fc;
            }
            .iconfont {
                transform: rotate(-90deg);
                margin-bottom: 10px;
            }
        }
    }
    .submit {
        width: 108px;
    }
}
